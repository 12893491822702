import React, { useEffect } from 'react'

export default function Child () {
  useEffect(() => {
    const fn = (event: any) => {
      if (event.data === 'test') {
        try {
          window.localStorage.setItem('foo', 'bar')
          window.parent.postMessage('localStorage: OK', event.origin)
        } catch (err) {
          window.parent.postMessage(`localStorage FAILED with error: "${err.message}"`, event.origin)
        }

        try {
          window.sessionStorage.setItem('foo', 'bar')
          window.parent.postMessage('sessionStorage: OK', event.origin)
        } catch (err) {
          window.parent.postMessage(`sessionStorage: FAILED with error: "${err.message}"`, event.origin)
        }

        window.parent.postMessage('complete', event.origin)
      }
    }

    window.addEventListener('message', fn, false)

    return () => {
      window.removeEventListener('message', fn, false)
    }
  }, [])

  return (
    <div/>
  )
}
