import React from 'react'
import { theme as authereumTheme } from '@authereum/mui-theme'
import { createMuiTheme, MuiThemeProvider } from '@material-ui/core/styles'
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom'
import Index from './components/Index'
import Child from './components/Child'
import NotFound from './components/NotFound'
import './App.css'

const muiTheme = createMuiTheme(authereumTheme)

function App () {
  return (
    <MuiThemeProvider theme={muiTheme}>
      <Router>
        <Switch>
          <Route path="/" exact={true} component={Index} />
          <Route path="/child" component={Child} />
          <Route component={NotFound} />
        </Switch>
      </Router>
    </MuiThemeProvider>
  )
}

export default App
