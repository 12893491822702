import React, { useEffect, useState } from 'react'
import platform from 'platform'
import LinearProgress from '@material-ui/core/LinearProgress'
import Button from '@material-ui/core/Button'

// TODO: use styled-components or useStyles for css

export default function Parent () {
  const [status, setStatus] = useState(1)
  const [complete, setComplete] = useState(false)
  const [results, setResults] = useState([] as any)
  const [browserInfo, setBrowserInfo] = useState(null as any)
  const [detailsExpanded, setDetailsExpanded] = useState(false)

  useEffect(() => {
    // the child url must be a different top-level domain then the parent
    // url for the browser to throw errors if cross-domain storage
    // is not allowed.
    let childUrl = 'https://authereum-browser-test.netlify.app'
    if (window.location.hostname === 'localhost') {
      const { protocol, host } = window.location
      childUrl = `${protocol}//${host}`
    }

    const iframe: any = document.createElement('iframe')
    iframe.src = `${childUrl}/child`
    iframe.style.position = 'fixed'
    iframe.style.bottom = '0'
    iframe.style.right = '0'
    iframe.style.width = '0'
    iframe.style.height = '0'
    iframe.style.border = '0'
    iframe.setAttribute('frameborder', '0')
    iframe.setAttribute('scrolling', 'no')

    const isBraveBrowser = async () => {
      try {
        const res = await (await fetch('https://api.duckduckgo.com/?q=useragent&format=json')).json()
        return res.Answer.includes('Brave')
      } catch (err) {
        console.error(err)
      }

      return false
    }

    isBraveBrowser()
    .then((isBrave: boolean) => {
      let { name, version, os } = platform
      let osName = ''
      if (os && os.family) {
        osName = os.family
      }

      if (isBrave) {
        name = 'Brave'
      }

      setBrowserInfo({ name, version, osName })
    })

    const fn = async (event: any) => {
      if (event.origin === childUrl) {
        const { data } = event
        if (data === 'complete') {
          setComplete(true)
        } else if (typeof data === 'string') {
          const ok = data.indexOf('OK') !== -1
          if (!ok) {
            setStatus(0)
          }

          results.push({ ok, data })
          setResults(results)
        }
      }
    }

    window.addEventListener('message', fn)

    iframe.onload = () => {
      // timeout is to ensure child is completely loaded and ready
      setTimeout(() => {
        iframe.contentWindow.postMessage('test', iframe.src)
      }, 100)
    }

    document.body.appendChild(iframe)

    return () => {
      window.removeEventListener('message', fn)
    }
  }, [results])

  const handleShowDetailsClick = (event: any) => {
    event.preventDefault()
    setDetailsExpanded(!detailsExpanded)
  }

  let hashtag = browserInfo ? `#${browserInfo.name.toLowerCase()}-${browserInfo.osName.toLowerCase() === 'ios' ? 'ios' : 'desktop'}` : ''

  return (
    <div id="container">
      <hgroup>
        <h1>
          <figure>
            <a href="/">
              <img src="https://assets.authereum.com/authereum_text_logo.svg" alt="Autheruem" />
            </a>
          </figure>
          <span>Browser Test</span>
        </h1>
      </hgroup>
      {complete ?
        <div id="output">
          {status ?
            <>
              <div id="compatibility" className="success"><span role="img" aria-label="">✅</span> Your browser is compatible with Authereum.</div>
            </>
          : <>
              <div id="compatibility" className="error"><span role="img" aria-label="">⚠️</span> Your browser is not compatible with Authereum. Visit the <a href={`https://docs.authereum.com/browser-compatibility${hashtag}`} target="_blank" rel="noopener noreferrer"><strong>browser compatibility</strong></a> support page for help in making your browser compatible with Authereum.</div>
            </>
          }

          {detailsExpanded ?
            <>
              <div id="detailsToggle">
                <Button
                  onClick={handleShowDetailsClick}>
                  Hide details ▲
                </Button>
              </div>
             {browserInfo ?
                <div id="browserInfo" className={status ? 'success' : 'warn'}>
                  <div>Browser: {browserInfo.name}</div>
                  <div>Version: {browserInfo.version}</div>
                  <div>OS: {browserInfo.osName}</div>
                </div>
              : null}

              {results.map((result: any, i: number) => {
                return (
                  <div key={i} className={`result ${result.ok ? 'success' : 'error'}`}>{result.data}</div>
                )
              })}
          </>
            :
              <>
                <div id="detailsToggle">
                  <Button
                    onClick={handleShowDetailsClick}>
                    Show details ▼
                  </Button>
                </div>
              </>
            }
            </div>
        :
        <>
          <div id="loader">Running test...</div>
          <LinearProgress />
        </>
      }
    </div>
  )
}
